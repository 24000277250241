<template>
  <div class="card">
    <div class="card-body">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <div class="mr-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="$router.push({name: 'one-news-page', params: {id:'new'}})"
          >
            Новая новость
          </b-button>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Поиск</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">

            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <router-link
                  :to="{name: 'one-news-page', params:{id: props.row.id}}"
                  class="edit-button"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Редактировать</span>
                </router-link>

                <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click="newsToDelete=props.row.id"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Удалить</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <infinite-loading
        spinner="bubbles"
        @infinite="infiniteScroll"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
    <div>
      <!-- button trigger -->
      <div class="demo-inline-spacing" />

      <!-- modal -->
      <b-modal
        id="modal-primary"
        v-model="modalIsOpen"
        ok-only
        ok-title="Отмена"

        modal-class="modal-primary"
        centered
        title="Primary Modal"
      >
        <div>Вы действительно хотите удалить новость?</div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-1"
          variant="primary"
          @click="deleteItem(newsToDelete)"
        >
          Удалить
        </b-button>
      </b-modal>

    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import InfiniteLoading from 'vue-infinite-loading'
import crud_module from '../../libs/crud_module'

const news_module = crud_module({
  entity_name: 'news',
})

export default {
  components: {
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    InfiniteLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skipCount: 6,
      maxResultCount: 6,
      modalIsOpen: false,
      newsToDelete: null,
      columns: [
        {
          label: 'Название новости',
          field: 'title',
        },
        {
          label: 'Статус',
          field: 'email',
        },
        {
          label: 'Дата',
          field: this.formatDate,
        },
        {
          label: 'Действие',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  async created() {
    try {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCount,
      }
      this.rows = await news_module.fetchItems(payload)
        .then(res => res.data.rows)
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async infiniteScroll($state) {
      const payload = {
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount,
      }
      await news_module.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async deleteItem(id) {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCount,
      }
      await news_module.deleteItem(id)
      await news_module.fetchItems(payload).then(response => {
        this.rows = response.data.rows
      })
      this.modalIsOpen = false
    },
    formatDate(rowObj) {
      const newDate = new Date(rowObj.createdAt)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const newStr = newDate.toLocaleDateString('ru', options)
      return newStr
    },
  },
}
</script>

<style lang="scss">
  .edit-button{
    padding: 0.65rem 1.28rem;
    display: flex;
    align-items: center;

    &:hover{
      background-color: rgba(115, 103, 240, 0.12);
    }
  }
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
